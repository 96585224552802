import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from '../../components/mini/seo';

import Layout from '../../components/mini/layout';
import FlexCard from '../../components/mini/flexcard';
import DrivingSchoolCard from '../../components/drivingschoolcard';
import Tracks from '../../components/mini/multipletrack';
import settings from '../../../settings';

const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const minimastercard = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-mastercard.jpg`;
const creditCard = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-cc-home.jpg`;
const holidayBanner = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-holiday.jpg`;
const ministuntcar = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-stunt-driving-school-box.jpg`;
const miniconeboxescar = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-autocross-cones-box.jpg`;
const minidriftingcar = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-stunt-drifting-class-box.jpg`;
const herobackground = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-main-banner.jpg`;
const miniscclassbox = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-sc-class-box.jpg`;
const trackLegendBanner = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-track-legend.jpg`;
const mothersDayBanner = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-mom-sale.jpg`;
const fathersDayBanner = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-fathers-day.jpg`;

const today = new Date();
const mothersDayOffer = 
  today >= new Date(2025,3,1) && 
  today < new Date(2025,4,17);
const fathersDayOffer = 
  today >= new Date(2025,4,17) && 
  today < new Date(2025,5,21);
   

class IndexPage extends React.Component {
  scrollToRef = () => {
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: 'smooth'
    });
  };
  
  rowNum = 0;

  getRowType =(rowNum) => {
    if (rowNum % 2 === 0) {
      return "row-reverse";
    } else {
      return "row";
    }
  };

  trackLegendCard = () => {
    return (
      <FlexCard
          rowType={this.getRowType(++this.rowNum)}
          image={trackLegendBanner}
          imageAlt="TRACK LEGEND"
          header="TRACK LEGEND"
          subheader="MEET THE JOHN COOPER WORKS MINI"
          text="With quick wits and a punchy turbocharged engine, a JCW-tuned MINI is sure to put a smile on your face. The MINI Driving Experiences features them exclusively."
        >
        </FlexCard>
    )
  }

  getFirstCard = () => {
    if(mothersDayOffer) {
      return (
        <>
        <FlexCard
          rowType={this.getRowType(++this.rowNum)}
          image={mothersDayBanner}
          imageAlt="MINIS + MOMS"
          header="MINIS + MOMS"
          subheader="GET 20% OFF A MINI CLASS"
          text="Get mom some motorin’ for Mother’s Day with 20% off a MINI class in either location"
          btnText="EXPLORE CLASSES"
          btnStyle="btn-black"
          externalURL="/mini/schools"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class now and use code <span className="bold">25MDM20</span>. Must book class by 5/16/25.
          </p>
        </FlexCard>
        {this.trackLegendCard()}
        </>
      );
    } else if (fathersDayOffer) {
      return (
        <>
          <FlexCard
            rowType={this.getRowType(++this.rowNum)}
            image={fathersDayBanner}
            imageAlt="GET GOING THIS FATHER’S DAY"
            header="GET GOING THIS FATHER’S DAY"
            subheader="GET 20% OFF A MINI CLASS"
            text="Dad’s going to love driving a MINI on our track, and right now you can get a class for 20% off at either location. "
            btnText="EXPLORE CLASSES"
            btnStyle="btn-black"
            externalURL="/mini/schools"
          >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class now and use code <span className="bold">25FDM20</span>. Must book class by 6/20/25. 
          </p>
        </FlexCard>
        {this.trackLegendCard()}
        </>
      );
    }
    else {
      return (
        <>
        {this.trackLegendCard()}
        </>
      )
    }
  }


  getCards = () => {
    return (
      <>
        {this.getFirstCard()}
        <FlexCard
          rowType={this.getRowType(++this.rowNum)}
          image={creditCard}
          imageAlt="Mini magic"
          header="Fuel The Fun"
          subheader="SAVE ON CLASSES WITH THE MINI CARD"
          text="Accelerate your rewards with the MINI Card – exclusively for MINI drivers. Redeem for unlimited cash back toward your MINI Financial Services monthly payment or travel. Plus, you’ll save when you book a class with us over the phone."
          btnText="Learn More"
          btnStyle="btn-black"
          externalURL="https://www.myminicreditcard.com/35343"
        />
      </>
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="MINI Driving Experience USA | MINI Driving School" description="Over 50 years of motorsport legacy goes into the MINI Driving Experience in the form of Group Events, Stunt Driving School and autocross and track school." />

        <div className="mini__hero">
          <LazyLoadImage effect="blur" src={herobackground} alt="MINI" />
          <button className="arrow" onClick={this.scrollToRef} onKeyDown={this.scrollToRef} title="arrow">
            <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
          </button>
        </div>
        <section className="performance-center">
          <div className="container">
            <div className="drivingschool__header">
              <h1 className="linethrough-header">THE MINI DRIVING EXPERIENCE</h1>
              <p>
                Over 50 years of motorsport legacy live in each John Cooper Works MINI, something you can<br/> experience for yourself at the MINI Driving Experience.
              </p>
            </div>
          </div>
          {this.getCards()}
        </section>

        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2 className="linethrough-header">Mini fun, now in two locations</h2>
            </div>
          </div>
        </section>
        <Tracks />
        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2 className="linethrough-header">mini driving experience schools</h2>
            </div>
          </div>
          <DrivingSchoolCard
            rowType="row"
            image={miniconeboxescar}
            imageAlt="Mini Stunt Drifting Class Box"
            header="MINI AUTOCROSS & TRACK SCHOOL"
            text="Learn the basics of fun behind the wheel of a John Cooper Works MINI on our closed course with professional driving instructors."
            subheaderMini="THE BASICS are anything but."
            learnUrl="/mini/schools"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-red"
            bookUrl="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=MINI&class=MINI_Autocross_%26_Track_School"
          />
          {/* <DrivingSchoolCard
            rowType="row"
            image={minidriftingcar}
            imageAlt="Mini Stunt Driving School Box"
            header="MINI stunt driving school"
            subheaderMini="pull some stunts, be a hero."
            text="Live the dream of sliding into the perfect parking spot and hitting the e-brake for some J-turn action. Looks cool, feels even better."
            learnUrl="/mini/schools"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-red"
            bookUrl="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=MINI&class=MINI_Stunt_Driving_School"
          /> */}
          <DrivingSchoolCard
            rowType="row"
            image={miniscclassbox}
            imageAlt="MINI driving experience"
            header="MINI driving experience"
            subheaderMini="LIKE DRIVING A MINI RACE CAR"
            text="Now available on the East Coast, the MINI Driving Experience packs tons of adrenaline in just 4 hours. Buckle up."
            learnUrl="/mini/schools"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-red"
            bookUrl="https://pds.eventsbmw.com/?location=Spartanburg,%20SC&brand=MINI&class=MINI_Driving_Experience"
          />
        </section>
      </Layout>
    );
  }
}

export default IndexPage;